import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../utils/axios';

const PublicEventPage = () => {
    const { eventId } = useParams();
    const [eventDetails, setEventDetails] = useState(null);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [guestEntry, setGuestEntry] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        number_of_men: '',
        number_of_women: '',
        arrival_date: '',
        departure_date: '',
        comments: '',
        countryCode: '+1',

    });

    useEffect(() => {
        // Fetch event details without requiring a token
        const fetchEventDetails = async () => {
            try {
                const response = await apiClient.get(`/events/${eventId}/`);
                setEventDetails(response.data);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        };

        if (eventId) fetchEventDetails();
    }, [eventId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGuestEntry(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formattedPhoneNumber = `${guestEntry.countryCode}${guestEntry.phone_number.replace(/[^0-9]/g, '')}`;
    
        // Use eventId from useParams directly instead of eventDetails.id
        const payload = {
            ...guestEntry,
            event: eventId, // Directly use eventId here
            phone_number: formattedPhoneNumber,
            user: eventDetails.user.id,
        };
    
        try {
            const response = await apiClient.post(`/guestlistentries/`, payload);
            if (response.status === 201) {
                setIsSuccessModalVisible(true); // Show success modal on successful submission
            } else {
                setIsErrorModalVisible(true); // Show error modal on failure
            }
        } catch (error) {
            console.error('Error submitting guest entry:', error);
            setIsErrorModalVisible(true);
        }
    };
    

    // Success Modal Component
    const SuccessModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg">
                <h2 className="text-lg font-bold">Submission Successful!</h2>
                <p>Your entry has been successfully added to the guest list.</p>
                <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => setIsSuccessModalVisible(false)}>Close</button>
            </div>
        </div>
    );

    // Error Modal Component
    const ErrorModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg">
                <h2 className="text-lg font-bold">Error!</h2>
                <p>There was an error processing your submission. Please try again.</p>
                <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded" onClick={() => setIsErrorModalVisible(false)}>Close</button>
            </div>
        </div>
    );

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow-md">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <h3 className="text-xl font-medium text-gray-900">{eventDetails ? eventDetails.name : 'Loading event...'}</h3>
                    <p className="text-sm text-gray-500">{eventDetails ? eventDetails.description : ''}</p>
                    <div>
                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900">First Name</label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            value={guestEntry.first_name}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900">Last Name</label>
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            value={guestEntry.last_name}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={guestEntry.email}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                        <div className="flex">
                            <div className="relative flex items-center w-full">
                                <select
                                    name="countryCode"
                                    id="countryCode"
                                    value={guestEntry.countryCode}
                                    onChange={handleChange}
                                    className="z-10 py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                                >
                                    {/* Add other countries as needed */}
                                    <option value="+1">+1</option>
                                    <option value="+44">+44</option>
                                    <option value="+61">+61</option>
                                    <option value="+49">+49</option>
                                    <option value="+33">+33</option>
                                </select>
                                <input
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    value={guestEntry.phone_number}
                                    onChange={handleChange}
                                    placeholder="1234567890"
                                    required
                                    className="p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-r-lg border border-l-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="number_of_men" className="block mb-2 text-sm font-medium text-gray-900">Men</label>
                        <input
                            type="number"
                            name="number_of_men"
                            id="number_of_men"
                            value={guestEntry.number_of_men}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="number_of_women" className="block mb-2 text-sm font-medium text-gray-900">Women</label>
                        <input
                            type="number"
                            name="number_of_women"
                            id="number_of_women"
                            value={guestEntry.number_of_women}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="arrival_date" className="block mb-2 text-sm font-medium text-gray-900">Arrival Date</label>
                        <input
                            type="date"
                            name="arrival_date"
                            id="arrival_date"
                            value={guestEntry.arrival_date}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="departure_date" className="block mb-2 text-sm font-medium text-gray-900">Departure Date</label>
                        <input
                            type="date"
                            name="departure_date"
                            id="departure_date"
                            value={guestEntry.departure_date}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="comments" className="block mb-2 text-sm font-medium text-gray-900">Comments</label>
                        <input
                            type="text_area"
                            name="comments"
                            id="comments"
                            value={guestEntry.comments}
                            onChange={handleChange}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                        />
                    </div>
                    <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit Entry</button>
                </form>
                {isSuccessModalVisible && <SuccessModal />}
                {isErrorModalVisible && <ErrorModal />}
            </div>
        </div>
    );
};

export default PublicEventPage;
