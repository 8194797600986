import React, { useState } from 'react';
import axios from 'axios';
import { TextInput, Button, HelperText, Card } from 'flowbite-react';

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.vipcheck.in/api/password-reset/', { email });
      setMessage('If your email is registered, you will receive a password reset link.');
    } catch (err) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
            <div className="flex flex-col md:flex-row min-h-screen">
            <div className="relative hidden md:flex md:w-1/2 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: 'url("https://images.unsplash.com/photo-1485872299829-c673f5194813?q=80&w=1460&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")' }}>
                {/* Black overlay with 25% opacity */}
                <div className="absolute inset-0 bg-black opacity-50"></div>

                {/* Flex container for centering content */}
                <div className="absolute inset-0 p-10 flex items-center z-10">
            <div className="text-left">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl">Never miss a beat of the city's pulse</h1>
                <p className="text-lg text-gray-200">With VIPCheckin, you're always in tune with the latest guestlist buzz. Our platform ensures that every enthusiast eager to join your curated events is accounted for, seamlessly and in real time.</p>
            </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <Card className="w-full max-w-md p-8">
            <div className="flex flex-col items-center"> {/* Center logo and text */}
                {/* Logo Image */}
                <img
                src="https://eleven12.s3.us-west-2.amazonaws.com/vipcheckin_logo_full_gold.svg" // Replace with the path to your logo image
                alt="Logo"
                className="mb-4 w-24 h-24 object-cover object-center" // Set the size of your logo. Adjust w-24 and h-24 as needed.
                />
            </div>
            <h2 className=" text-2xl font-bold">Let's reset your password</h2>
            <p>Enter your email below to reset your password.</p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <TextInput
            type="email"
            placeholder="Enter your email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            /> 
            <Button type="submit">Reset Password</Button>
            {message && <HelperText>{message}</HelperText>}
            {error && <HelperText valid={false}>{error}</HelperText>}
            <hr/>
            <HelperText>Don't have an account? <a href="/signup">Sign up</a></HelperText>
          </form>
        </Card>
      </div>
    </div>
    );
}

export default ForgotPasswordForm;