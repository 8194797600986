import React, { useState, useEffect } from 'react';
import apiClient from '../../utils/axios'; // Ensure this path is correct

const GuestListPage = () => {
    const [guestListEntries, setGuestListEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedGuests, setSelectedGuests] = useState(new Set());

    useEffect(() => {
        const fetchGuestListEntries = async () => {
            try {
                setIsLoading(true);
                const response = await apiClient.get('/guestlistentries/');
                setGuestListEntries(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching guest list entries:', error);
                setIsLoading(false);
            }
        };

        fetchGuestListEntries();
    }, []);

    const toggleGuestSelection = (guestId) => {
        const newSelectedGuests = new Set(selectedGuests);
        if (newSelectedGuests.has(guestId)) {
            newSelectedGuests.delete(guestId);
        } else {
            newSelectedGuests.add(guestId);
        }
        setSelectedGuests(newSelectedGuests);
    };

    const toggleSelectAll = () => {
        if (selectedGuests.size === guestListEntries.length) {
            setSelectedGuests(new Set());
        } else {
            const allGuestIds = guestListEntries.map((guest) => guest.id);
            setSelectedGuests(new Set(allGuestIds));
        }
    };

    const filteredGuestListEntries = searchQuery
      ? guestListEntries.filter(
          (guest) =>
            guest.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            guest.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            guest.email.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : guestListEntries;

    const exportSelectedGuests = () => {
        const csvContent = "data:text/csv;charset=utf-8," + 
            Array.from(selectedGuests).map(id => {
                const guest = guestListEntries.find(g => g.id === id);
                return `${guest.first_name},${guest.last_name},${guest.email},${guest.phone_number}, ${guest.venue_info.venue_name}, ${guest.event_info.event_name}, ${guest.number_of_men}"M", ${guest.number_of_women}"W", ${guest.arrival_date}, ${guest.departure_date}, ${guest.comments}`;
            }).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "guestlist.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <section className="py-3 sm:py-5">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Guest List</h1>
                    <div className="flex items-center space-x-4">
                        <div className="relative">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search guests..."
                                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            />
                        </div>
                        <button
                            onClick={exportSelectedGuests}
                            className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-800"
                        >
                            Export Selected
                        </button>
                    </div>
                </div>
                <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="checkbox"
                                            checked={selectedGuests.size === guestListEntries.length && guestListEntries.length > 0}
                                            onChange={toggleSelectAll}
                                            className="checkbox" // Add appropriate classes for styling
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3">Guest Name</th>
                                    <th scope="col" className="px-6 py-3">Email</th>
                                    <th scope="col" className="px-6 py-3">Phone Number</th>
                                    <th scope="col" className="px-6 py-3">Venue / Event</th>
                                    <th scope="col" className="px-6 py-3">Men</th>
                                    <th scope="col" className="px-6 py-3">Women</th>
                                    <th scope="col" className="px-6 py-3">Arrival Date</th>
                                    <th scope="col" className="px-6 py-3">Departure Date</th>
                                    <th scope="col" className="px-6 py-3">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredGuestListEntries.map((guest) => (
                                    <tr key={guest.id} className="border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="px-6 py-4">
                                            <input
                                                type="checkbox"
                                                checked={selectedGuests.has(guest.id)}
                                                onChange={() => toggleGuestSelection(guest.id)}
                                                className="checkbox" // Add appropriate classes for styling
                                            />
                                        </td>
                                        <td className="px-6 py-4">{guest.first_name} {guest.last_name}</td>
                                        <td className="px-6 py-4">{guest.email}</td>
                                        <td className="px-6 py-4">{guest.phone_number}</td>
                                        <td className="px-6 py-4">{guest.venue_info.venue_name}/{guest.event_info.event_name}</td>
                                        <td className="px-6 py-4">{guest.number_of_men}</td>
                                        <td className="px-6 py-4">{guest.number_of_women}</td>
                                        <td className="px-6 py-4">{guest.arrival_date}</td>
                                        <td className="px-6 py-4">{guest.departure_date}</td>
                                        <td className="px-6 py-4">{guest.comments}</td>
                                        {/* Add other guest details */}
                                        <td className="px-6 py-4">
                                            {/* Implement action buttons for edit/delete here */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GuestListPage;
