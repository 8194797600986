import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CreateVenueForm = ({ closeModal, onVenueCreated }) => {
    const [venueData, setVenueData] = useState({
        name: '',
        description: '',
        location: '',
    });
    const [isSuccess, setIsSuccess] = useState(false); // Correct state name to isSuccess
    const navigate = useNavigate();

    const handleChange = (e) => {
        setVenueData({ ...venueData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access_token');
        try {
            await axios.post('https://api.vipcheck.in/api/venues/', venueData, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setIsSuccess(true); // Set success state to true
        } catch (error) {
            console.error('Error creating venue:', error.response);
        }
    };

    if (isSuccess) {
        // After success, instead of immediately navigating, show success message with a button
        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 p-4 sm:p-6">
                        <div className="text-center p-4">
                            <h3 className="mb-3 text-lg font-medium">Venue Created Successfully!</h3>
                            <button 
                                onClick={() => {
                                  closeModal(); // Close the modal
                                  onVenueCreated(); // Refresh the venue list in parent component
                                }} 
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 p-4 sm:p-6">
                    {/* Modal content including form */}
                    <div className="flex justify-between items-start p-4 rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
                            Create New Venue
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={closeModal}>
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <div>
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Venue Name</label>
                            <input type="text" name="name" id="name" value={venueData.name} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" placeholder="Venue Name" required />
                        </div>
                        <div>
                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                            <textarea name="description" id="description" value={venueData.description} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" placeholder="Venue Description" required />
                        </div>
                        <div>
                            <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Location</label>
                            <input type="text" name="location" id="location" value={venueData.location} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" placeholder="Location" required />
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button type="button" onClick={closeModal} className="px-4 py-2 bg-gray-600 text-white text-sm font-medium rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500">Cancel</button>
                            <button type="submit" className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">Create Venue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateVenueForm;
