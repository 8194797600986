// VenuesPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Ensure this is imported
import VenueCard from '../VenueCard/VenueCard';
import CreateVenueForm from '../CreateVenueForm/CreateVenueForm';
import apiClient from '../../utils/axios';

const VenuesPage = () => {
    const [showCreateVenueModal, setShowCreateVenueModal] = useState(false);
    const [venues, setVenues] = useState([]);
    const navigate = useNavigate(); // Use navigate for redirection

    const fetchVenues = async () => {
        try {
            const response = await apiClient.get('/venues/');
            setVenues(response.data);
        } catch (error) {
            console.error('Error fetching venues:', error);
        }
    };

    useEffect(() => {
        fetchVenues();
    }, []);

    const toggleCreateVenueModal = () => {
        setShowCreateVenueModal(!showCreateVenueModal);
    };

    const handleEditVenue = async (venueId, editedVenue) => {
        try {
            await apiClient.put(`/venues/${venueId}/`, editedVenue);
            fetchVenues(); // Refresh the list
        } catch (error) {
            console.error('Error updating venue:', error);
        }
    };

    const handleDeleteVenue = async (venueId) => {
        try {
            await apiClient.delete(`/venues/${venueId}/`);
            fetchVenues(); // Refresh the list
        } catch (error) {
            console.error('Error deleting venue:', error);
        }
    };
    
    const handleVenueCreated = () => {
        fetchVenues(); // Refresh the venues list
        setShowCreateVenueModal(false); // Close the modal
        navigate('/dashboard'); // This line might be redundant if you're already on the venues page
    };

    return (
        <div className="p-4">
            <button onClick={toggleCreateVenueModal} className="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Create New Venue
            </button>

            {showCreateVenueModal && (
                <CreateVenueForm closeModal={toggleCreateVenueModal} onVenueCreated={handleVenueCreated} />
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {venues.map(venue => (
                    <VenueCard
                        key={venue.id}
                        venue={venue}
                        onEdit={handleEditVenue}
                        onDelete={handleDeleteVenue}
                    />
                ))}
            </div>
        </div>
    );
};

export default VenuesPage;
