import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextInput, Button, HelperText, Card } from 'flowbite-react';



function ResetPasswordForm() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = queryParams.get('token');
  const emailFromUrl = queryParams.get('email');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');
    setToken(tokenFromUrl);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
        await axios.post('https://api.vipcheck.in/api/password-reset-confirm/', { 
          token: tokenFromUrl, 
          email: emailFromUrl, 
          password 
        });
        setMessage('Your password has been reset successfully.');
      } catch (err) {
        setError('An error occurred. Please try again later.');
      }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* ... Similar styling and layout as ForgotPasswordForm ... */}
      <div className="flex-1 flex items-center justify-center">
        <Card className="w-full max-w-md p-8">
            <h2 className=" text-2xl font-bold">Create your new password</h2>
            <p>Enter your new password below.</p>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <TextInput
              type="password"
              placeholder="Enter your new password"
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextInput
              type="password"
              placeholder="Confirm your new password"
              required={true}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button type="submit">Reset Password</Button>
            {message && <HelperText>{message}</HelperText>}
            {error && <HelperText valid={false}>{error}</HelperText>}
          </form>
        </Card>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
