import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../../utils/axios';
import { copyToClipboard } from '../../utils/helpers';

const EventDetail = () => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [eventDetails, setEventDetails] = useState(null);
    const [guestListEntries, setGuestListEntries] = useState([]);
    const [venues, setVenues] = useState([]); // Corrected initialization
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false); // Corrected initialization
    const [editableEvent, setEditableEvent] = useState({});
    const [selectedGuest, setSelectedGuest] = useState({});

    useEffect(() => {
        const fetchEventAndVenues = async () => {
            const token = localStorage.getItem('access_token');
            const headers = { Authorization: `Bearer ${token}` };

            try {
                // Fetch event details
                const eventResponse = await apiClient.get(`/events/${eventId}/`, { headers });
                setEventDetails(eventResponse.data);

                // Fetch guest list entries
                const guestListResponse = await apiClient.get(`/events/${eventId}/guestlistentries`, { headers });
                setGuestListEntries(guestListResponse.data);

                // Fetch venues
                const venuesResponse = await apiClient.get('/venues/', { headers });
                setVenues(venuesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEventAndVenues();
    }, [eventId]);

    const handleBackClick = () => navigate('/dashboard');

    const handleEditGuest = (guest) => {
        setSelectedGuest(guest);
        setIsModalOpen(true);
    };

    const handleDeleteGuest = async (guestId) => {
        try {
            const token = localStorage.getItem('access_token');
            const headers = { Authorization: `Bearer ${token}` };
            const response = await apiClient.delete(`/guestlistentries/${guestId}/`, { headers });
            if (response.status === 204) {
                setGuestListEntries(guestListEntries.filter(entry => entry.id !== guestId));
            }
        } catch (error) {
            console.error('Error deleting guest:', error);
        }
    };

    const handleAddGuest = () => {
        setSelectedGuest({});
        setIsModalOpen(true);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Retrieve the username from localStorage
        const username = localStorage.getItem('username');
    
        // Check if username is retrieved successfully
        if (!username) {
            console.error('No username found in localStorage');
            return; // Exit the function if no username is found
        }
    
        try {
            // Fetch user ID using the username
            const token = localStorage.getItem('access_token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
    
            // Replace '/api/get-user-id/' with your actual API endpoint
            const userResponse = await apiClient.get(`/get-user-id/${username}/`, { headers });
            const userId = userResponse.data.id;
    
            // Prepare guest data with user ID
            const guestData = {
                ...selectedGuest,
                event: eventId,
                user: userId, // user ID, not username
                venue: eventDetails.venue.id
            };
    
            // Submit guest data
            const url = selectedGuest.id ? `/guestlistentries/${selectedGuest.id}/` : '/guestlistentries/';
            const method = selectedGuest.id ? 'put' : 'post';
            const response = await apiClient[method](url, guestData, { headers });
    
            if (response.status === 200 || response.status === 201) {
                setGuestListEntries(prev => selectedGuest.id 
                    ? prev.map(item => item.id === selectedGuest.id ? response.data : item) 
                    : [...prev, response.data]);
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error('Error submitting guest data:', error);
        }
    };

    const handleEventSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('access_token');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
    
            const response = await apiClient.put(`/events/${eventId}/`, editableEvent, { headers });
    
            if (response.status === 200) {
                setEventDetails(response.data);
                setIsEventModalOpen(false);
            }
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };

    const handleDeleteEvent = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const headers = { Authorization: `Bearer ${token}` };
            const response = await apiClient.delete(`/events/${eventId}/`, { headers });

            if (response.status === 204) { // Check your API's correct success status code, might be 200 or 204
                console.log('Event deleted successfully');
                navigate('/dashboard'); // Redirect to dashboard or appropriate page after deletion
            }
        } catch (error) {
            console.error('Error deleting event:', error);
            // Handle deletion error (optional: show a message to the user)
        }
    };

    

    if (isLoading) {
        return <div>Loading event details...</div>;
    }

    if (!eventDetails) {
        return <div>Event details not available.</div>;
    }

    {/*Export Function*/}
    const handleExportCSV = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const headers = { Authorization: `Bearer ${token}` };
            const response = await apiClient.get(`/events/${eventId}/export-guestlist-csv/`, { headers, responseType: 'blob' });

            // Create a new Blob object using the response data of the file
            const file = new Blob([response.data], { type: 'text/csv' });
            // Create a URL for the file object
            const fileURL = URL.createObjectURL(file);
            // Create a temporary anchor element
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `event_${eventId}_guestlist.csv`);
            // Append the anchor to the body
            document.body.appendChild(fileLink);
            // Trigger download
            fileLink.click();
            // Clean up
            fileLink.remove();
            URL.revokeObjectURL(fileURL);
        } catch (error) {
            console.error('Error exporting guest list as CSV:', error);
        }
    };

        // Function to generate the public event URL
        const getPublicEventUrl = (eventId) => `${window.location.origin}/public-events/${eventId}`;

    
        const handleViewPublicPage = () => {
            const publicEventUrl = getPublicEventUrl(eventDetails.id);
            window.open(publicEventUrl, '_blank'); // Opens the public event page in a new tab
        };
    
        const handleCopyLink = () => {
            const publicEventUrl = getPublicEventUrl(eventDetails.id);
            copyToClipboard(publicEventUrl);
        };


    return (
        <div className="p-4 w-full">
            <button onClick={handleBackClick} className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 mb-4">
                Go Back to Dashboard
            </button>            
            <div className="w-full block p-6 my-6 bg-white border border-gray-200 rounded-lg shadow">
            <div className="flex items-center gap-4">
                <div className="flex-grow">
                    <h1 className="text-2xl font-bold text-gray-700 dark:text-white">{eventDetails.name}</h1>
                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                        {eventDetails.description}
                    </p>
                </div>
                <button onClick={() => { setEditableEvent(eventDetails); setIsEventModalOpen(true); }} className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 mb-4">
                    Edit Event
                </button>
            </div>
            </div>
            <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow w-full">
            <div className="flex items-center gap-4">
                <div className="flex-grow">
                <h2 className="text-2xl font-bold text-gray-700 dark:text-white py-4">Guest List</h2>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="flex gap-2">
                    <button onClick={handleAddGuest} className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">Add Guest</button>
                    <button onClick={handleExportCSV} className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-800">Export Guest List as CSV</button>
                    <button
                    onClick={handleViewPublicPage}
                    className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    View Public Page
                </button>
                <button
                    onClick={handleCopyLink}
                    className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Copy Public Link
                </button>
                    <div className="flex justify-end space-x-2 mb-4">
            </div>
                </div>
            </div>
            </div>


            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Full Name</th>
                            <th scope="col" className="px-6 py-3">Email</th>
                            <th scope="col" className="px-6 py-3">Phone Number</th>
                            <th scope="col" className="px-6 py-3">Venue & Event</th>
                            <th scope="col" className="px-6 py-3">Men</th>
                            <th scope="col" className="px-6 py-3">Women</th>
                            <th scope="col" className="px-6 py-3">Arrival date</th>
                            <th scope="col" className="px-6 py-3">Departure date</th>
                            <th scope="col" className="px-6 py-3">Comments</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guestListEntries.map((entry) => (
                            <tr key={entry.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">{entry.first_name} {entry.last_name}</td>
                                <td className="px-6 py-4">{entry.email}</td>
                                <td className="px-6 py-4">{entry.phone_number}</td>
                                <td className="px-6 py-4">{entry.venue_info.venue_name}/{entry.event_info.event_name}</td>
                                <td className="px-6 py-4">{entry.number_of_men}</td>
                                <td className="px-6 py-4">{entry.number_of_women}</td>
                                <td className="px-6 py-4">{entry.arrival_date}</td>
                                <td className="px-6 py-4">{entry.departure_date}</td>
                                <td className="px-6 py-4">{entry.comments}</td>
                                <td className="px-6 py-4 text-left">
                                    <a href="#" onClick={() => handleEditGuest(entry)} className="text-blue-600 hover:text-blue-900 mr-4">Edit</a>
                                    <a href="#" onClick={() => handleDeleteGuest(entry.id)} className="text-red-600 hover:text-red-900">Delete</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                    <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 p-4 sm:p-5">
                            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    {selectedGuest.id ? 'Edit Guest' : 'Add Guest'}
                                </h3>
                                <button onClick={() => setIsModalOpen(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit} action="#" className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div>
                                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            value={selectedGuest.first_name || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, first_name: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="First Name"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            value={selectedGuest.last_name || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, last_name: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Last Name"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={selectedGuest.email || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, email: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Email"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                                        <input
                                            type="phone_number"
                                            name="phone_number"
                                            id="phone_number"
                                            value={selectedGuest.phone_number || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, phone_number: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Phone Number"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="arrival_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Arrival Date</label>
                                        <input
                                            type="date"
                                            name="arrival_date"
                                            id="arrival_date"
                                            value={selectedGuest.arrival_date || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, arrival_date: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Arrival Date"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="departure_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Departure Date</label>
                                        <input
                                            type="date"
                                            name="departure_date"
                                            id="departure_date"
                                            value={selectedGuest.departure_date || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, departure_date: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Departure Date"
                                            required
                                        />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="comments" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Comments</label>
                                        <textarea
                                            type="text_area"
                                            name="comments"
                                            id="comments"
                                            value={selectedGuest.comments || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, comments: e.target.value })}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Comments"
                                            required
                                        />
                                </div>
                                <div>
                                        <label htmlFor="number_of_men" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Number of men</label>
                                        <input
                                            type="number"
                                            name="number_of_men"
                                            id="number_of_men"
                                            value={selectedGuest.number_of_men || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, number_of_men: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Number of men"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="number_of_women" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Number of women</label>
                                        <input
                                            type="number"
                                            name="number_of_women"
                                            id="number_of_women"
                                            value={selectedGuest.number_of_women || ''}
                                            onChange={e => setSelectedGuest({ ...selectedGuest, number_of_women: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Number of women"
                                            required
                                        />
                                    </div>

                                    {/* Additional input fields for last_name, email, phone_number, etc. */}
                                <div className="sm:col-span-2">
                                <button onClick={handleSubmit} className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 mb-4">
                                    Submit
                                </button>  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {isEventModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                    <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 p-4 sm:p-6">
                            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Edit Event
                                </h3>
                                <button onClick={() => setIsEventModalOpen(false)} className="text-gray-400 bg-transparent hover:text-gray-900 hover:bg-gray-200 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                </button>
                            </div>
                            <form onSubmit={handleEventSubmit} className="grid gap-4 mb-4 sm:grid-cols-2">
                                <div>
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Event Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={editableEvent.name || ''}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, name: e.target.value })}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                        required
                                    />
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                    <textarea
                                        id="description"
                                        name="description"
                                        value={editableEvent.description || ''}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, description: e.target.value })}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="start_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start Time</label>
                                    <input
                                        type="datetime-local"
                                        id="start_time"
                                        name="start_time"
                                        value={editableEvent.start_time || ''}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, start_time: e.target.value })}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="end_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End Time</label>
                                    <input
                                        type="datetime-local"
                                        id="end_time"
                                        name="end_time"
                                        value={editableEvent.end_time || ''}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, end_time: e.target.value })}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="venue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Venue</label>
                                    <select
                                        id="venue"
                                        name="venue"
                                        value={editableEvent.venue || ''}
                                        onChange={(e) => setEditableEvent({ ...editableEvent, venue: e.target.value })}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                        required
                                    >
                                        {venues.map(venue => (
                                            <option key={venue.id} value={venue.id}>{venue.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="sm:col-span-2 flex justify-start gap-4">
                                    <button type="submit" className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">
                                        Save Changes
                                    </button>
                                    <button
                                        onClick={handleDeleteEvent}
                                        className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800"
                                    >
                                        Delete Event
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventDetail;