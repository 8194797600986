import React, { useState, useEffect } from 'react';
import apiClient from '../../utils/axios'; // Import your configured axios instance
import { useNavigate } from 'react-router-dom'; // For redirecting to the forgot password page

function AccountManagementForm() {
    const [userDetails, setUserDetails] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
    });
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await apiClient.get('/users/me/');
                const { username, first_name, last_name, email } = response.data;
                setUserDetails({
                    username: username,
                    firstName: first_name,
                    lastName: last_name,
                    email: email,
                });
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        fetchUserDetails();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Construct the payload correctly as per your backend expectation
        const payload = {
            username: userDetails.username,
            first_name: userDetails.firstName,
            last_name: userDetails.lastName,
            email: userDetails.email,
            // username: userDetails.username, // Include or exclude as per your backend model
        };

        try {
            const response = await apiClient.put('/users/me/', payload);
            console.log(response.data); // Debug: Log the response
            setSuccessMessage('Account details updated successfully.');
            setTimeout(() => setSuccessMessage(''), 5000); // Clear success message after 5 seconds
        } catch (error) {
            console.error('Error updating user details:', error);
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="max-w-2xl px-4 py-8 mx-auto lg:py-16">
                <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Account Management</h2>
                {successMessage && <div className="mb-4 text-sm text-green-600">{successMessage}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                            <div className="sm:col-span-2">
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                <input
                                type="text"
                                name="username"
                                id="username"
                                value={userDetails.username}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={userDetails.username ? userDetails.username : "Enter your username"}
                                required
                            />
                            </div>    
                            <div className="sm:col-span-2">
                                <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={userDetails.firstName}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={userDetails.firstName ? userDetails.firstName : "Enter your first name"}
                                required
                            />
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={userDetails.lastName}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={userDetails.lastName ? userDetails.lastName : "Enter your last name"}
                                required
                            />
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address</label>
                                <input
                                type="email"
                                name="email"
                                id="email"
                                value={userDetails.email}
                                onChange={handleChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={userDetails.email ? userDetails.email : "Enter your first name"}
                                required
                            />
                            </div>
                        </div>
                    <div className="flex items-center space-x-4">
                        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Update Account
                        </button>
                        <button type="button" onClick={handleForgotPassword} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-900">
                            Change Password
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default AccountManagementForm;
