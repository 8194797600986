import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://api.vipcheck.in/api',
    timeout: 10000,
    headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('access_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Handling 401 errors and attempting to refresh the token
apiClient.interceptors.response.use(response => response, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            // Assuming you have an endpoint '/token/refresh/' that accepts a refresh token and returns a new access token
            const { data } = await axios.post('https://api.vipcheck.in/api/token/refresh/', { refresh: refreshToken });
            localStorage.setItem('access_token', data.access); // Assuming the new token is returned under 'access'
            apiClient.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
            originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
            return apiClient(originalRequest); // Retry the original request with the new token
        } catch (refreshError) {
            // Handle refresh token failure (e.g., redirect to login)
            console.error('Unable to refresh token:', refreshError);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            // Implement redirect to login or other error handling here
            return Promise.reject(refreshError);
        }
    }
    return Promise.reject(error);
});

export default apiClient;
