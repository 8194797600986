import React, { useState } from 'react';
import EditVenueModal from '../EditVenueModal/EditVenueModal'; // Import your edit modal component

const VenueCard = ({ venue, onEdit, onDelete }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (editedVenue) => {
    onEdit(venue.id, editedVenue); // Assuming onEdit takes an ID and the edited venue object
    setIsEditModalOpen(false);
  };

  const handleDelete = () => {
    onDelete(venue.id);
  };


  return (
    <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <h5 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">{venue.name}</h5>
      <p className="mb-4 text-base text-gray-500 dark:text-gray-400">{venue.description}</p>
      <p className="mb-4 text-base text-gray-500 dark:text-gray-400">{venue.location}</p>
      <div className="flex justify-between items-center">
        <button
          onClick={handleEditClick}
          className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
        >
          Edit
        </button>
        {isEditModalOpen && (
          <EditVenueModal
            venue={venue}
            onSave={handleSave}
            onClose={handleCloseModal}
            onDelete={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

export default VenueCard;
